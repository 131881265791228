import invariant from 'invariant';
import { Map, fromJS } from 'immutable';
import { CACHES_CLEARED_FOR_PAGES_EXCLUDING } from '@dbh/routing-redux';
import createApiCallHash from '@dbh/create-api-call-hash';
import { LOAD_WEBCONTROLS_FILTERED_SUCCESS, LOAD_WEBCONTROLS_SUCCESS } from '@dbh/webcontrols-events-and-constants-redux';
export { REDUX_AND_SAGA_KEY } from '@dbh/webcontrols-events-and-constants-redux';
import { withReducerUserLoginStatusChangedSsrHandler } from '@dbh/webpages-redux';
import { clearWebcontrolsCachesExcluding, makeWebcontrolKeyFromLocationPathname } from './index.js';
import 'redux-saga/effects';
import '@dbh/admindev-constants';
import '@dbh/environment';
import '@dbh/webpages-events-and-constants-redux';
import '@dbh/not-found-page-webcontrols';
import '@dbh/redux-route-internals';
import '@dbh/regex-utils';
import '@dbh/ssr-data-redux';
import '@dbh/with-conforms-to-for-production-www';
import 'prop-types';
import 'react-immutable-proptypes';
import 'reselect';
import '@dbh/lodash-extra';
import '@dbh/user-area-redux';
import '@dbh/reselect-extra';
import '@dbh/urls';
import '@dbh/invariant-errors-and-warnings';
import '@dbh/with-conforms-to-extra';
import 'react-router-redux';
import '@dbh/redux-extra';
import '@dbh/api-constants';
import '@dbh/configuration-redux';
import '@dbh/decorate-webcontrols-list';
import '@dbh/redux-saga-extra';
import '@dbh/request-queue-redux';
import '@dbh/saga-request-options';
import 'lodash/isObject';
import '@dbh/search-context-types';
import 'lodash/isPlainObject';
import 'lodash/isFinite';
import 'lodash/noop';
import '@dbh/generic-types';
import '@dbh/back-end-cdn-image-url-prop-type';

const initialState=Map({webcontrols:Map(),webcontrolsLoaded:Map(),webcontrolsApiCallsHashes:Map(),webcontrolsFiltered:Map(),webcontrolsFilteredLoaded:Map()}),webcontrolsReducer=function(a,b){void 0===a&&(a=initialState);const{type:c}=b;switch(c){case LOAD_WEBCONTROLS_SUCCESS:{const{payload:{locale:c,country:d,pathname:e,webcontrols:f,userAreaData:g,isUserLoaded:h}}=b;invariant(c&&d,"The LOAD_WEBCONTROLS_SUCCESS ("+LOAD_WEBCONTROLS_SUCCESS+") event must include valid `country` and `locale` in its payload.");const i=h?createApiCallHash(g):"",j=makeWebcontrolKeyFromLocationPathname(e);return a.withMutations(a=>a.setIn(["webcontrols",j],fromJS(f)).setIn(["webcontrolsLoaded",j],!0).setIn(["webcontrolsApiCallsHashes",j],i))}case LOAD_WEBCONTROLS_FILTERED_SUCCESS:{const{payload:{locale:c,country:d,webcontrol:e,apiParameters:f,webcontrolReference:g}}=b;invariant(c&&d&&g,"The LOAD_WEBCONTROLS_FILTERED_SUCCESS ("+LOAD_WEBCONTROLS_FILTERED_SUCCESS+") event must include valid `country`, `locale` and `webcontrolReference` in its payload.");const h=[d,c,g],i=fromJS({webcontrol:e,apiParameters:f});return a.withMutations(a=>a.setIn(["webcontrolsFiltered",...h],i).setIn(["webcontrolsFilteredLoaded",...h],!0))}case CACHES_CLEARED_FOR_PAGES_EXCLUDING:{const{payload:{country:c,locale:d,pathname:e}}=b;return clearWebcontrolsCachesExcluding(a,c,d,e)}default:return a}};var reducer = withReducerUserLoginStatusChangedSsrHandler("webcontrols",webcontrolsReducer);

export { reducer as default };
